import Card from 'react-bootstrap/Card';

const AboutMe = () => {

    return (
        <div>
            <Card.Header className="card-header">About Me</Card.Header>
            <Card.Body>
                <Card.Text>
                    Hey I'm <span style={{display: 'inline', fontSize: '2em'}}>Yoshi</span>, a recent graduate of UCSD currently pursuing a career as a Software Engineer! I like to solve challenging problems and systematically implement solutions that are clean, adaptable, and efficient. Software development is an art that can be refined, maintained, and appreciated with great communication and design pattern implementation. My goal is to be a reliable software engineer that can write code that is clear and concise to other developers, and produces satisfying results for the customer.
                </Card.Text>
            </Card.Body>
        </div>
    );
};

export default AboutMe;