import { NavLink } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';

const Navigation = () => {

    const styles = {
        navlink: {
            paddingLeft: '25px',
            paddingRight: '25px',
            fontWeight: 'Bold',
        },
    }

    return (
        <Navbar collapseOnSelect className="navbar-static-top" expand='sm' bg='dark' variant='dark'>
            <Container>
                <Navbar.Toggle aria-controls='responsive-navbar-nav' />
                <Navbar.Collapse id='response-navbar-nav'>
                    <Nav>
                        <Nav.Link style={styles.navlink} as={NavLink} to="/aboutme">About Me</Nav.Link>
                        <Nav.Link style={styles.navlink} as={NavLink} to="/projects">Projects</Nav.Link>
                        <Nav.Link style={styles.navlink} as={NavLink} to="./myresume.pdf" target="_blank" rel="noopener noreferrer">Resume</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
          
    );
};

export default Navigation;