import ProfilePic from './ProfilePic.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Sidebar = () => {

    const styles = {
        center: {
            textAlign: 'center',
            fontWeight: 'Bold',
        }
    }

    return (
        <Container>
            <Row>
                <Col className="d-flex justify-content-center" md={12}>
                    <ProfilePic />
                </Col>
            </Row>
            <Row>
                <Col className="d-flex flex-column" style={styles.center}>
                    <span>
                        <i className="fa fa-envelope" />  yrussell@ucsd.edu
                        <br/>
                    </span>
                    <span>
                        <i className="fa fa-mobile-phone" />  858-254-0481
                    </span>
                </Col>
            </Row>
        </Container>
    );
};

export default Sidebar;