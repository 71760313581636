import { Route, Switch, Redirect } from 'react-router-dom';
import AboutMe from './AboutMe.js';
import Projects from './Projects.js';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

const Display = () => {

    return (
        <Row>
            <Col md={9} style={{fontSize: '2vh'}}>
                <Card bg="dark">
                    <Switch>
                        <Route exact path = "/aboutMe" component={AboutMe} />
                        <Route exact path = "/">
                            <Redirect to="/aboutMe" />
                        </Route>
                        <Route exact path = "/projects" component={Projects} />
                    </Switch>
                </Card>
            </Col>
        </Row>
    );
};

export default Display;