import Sidebar from './components/Sidebar.js';
import Display from './components/Display.js';
import Navigation from './components/Navbar.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import './style/app.css';
import ColWrapper from './wrapper/ColWrapper';

function App() {

  const styles = {
    appContainer: {
      padding: 0,
      height: '100vh',
      backgroundColor: 'rgba(33, 33, 37, 0.7)',
      color: '#eaf6ff',
      textShadow: 'black',
      fontFamily: 'Montserrat, sans-serif',
      overflow: 'auto',
      fontSize: '2.5vh'
    },
    margin: {
      marginTop: '25px',
      marginBottom: '100px',
    }
  }
  return (
      <Container style={styles.appContainer} fluid>
          <Navigation />
          <Row className="d-flex align-items-center" style={styles.margin}>
              <ColWrapper md={4} Component={Sidebar} />
              <ColWrapper md={8} Component={Display} className="p-3" /> 
          </Row>
      </Container>
  );
}

export default App;
