import Col from 'react-bootstrap/Col';

const ColWrapper = ({ md=12, Component, className=null}) => {

    return (
        <Col md={md} className={className}>
            <Component />
        </Col>
    )
}

export default ColWrapper;