import Image from 'react-bootstrap/Image';
const ProfilePic = () => {

    const styles = {
        pfp: {
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    }
    return (
        <div style={styles.pfp} className="pfp">
            <Image src="./IMG_2794.JPG" alt="profilepic" fluid/>
        </div>
    );
};

export default ProfilePic;