import { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

const projectList = [
    {
        header: "I have tackled and accomplished many diverse projects that cover the various disciplines of Computer Science and Software Engineering.",
        body: 'Some of those projects include...'
    },
    {
        header: 'Implementing my own Operating System.',
        body: 'Using java, I designed and built my own operating system, implementing kernel threads, mutex locks, virtual memory, swap files, processes, condition variables, thread scheduler, exception handling, etc. A lot of Object-Oriented design and various datastructures were applied, and a clear understanding of a Linux Operating System and synchronization was needed to accomplish this project.'
    },
    {
        header: 'Creating a mini DropBox Application.',
        body: 'A program that saved local files remotely and remote files locally. Files were stored in the backend in configurable sized chunks, and also store sha-256 hashings of those chunks. In the process of syncing files, the program would compare the chunk hashings between the local and remote file and only download chunks whose hashes differed, minimizing network overhead of redownloading redundant data.'
    },
    {
        header: 'Finding relations between actors in the Hollywood industry based on IMDB data.',
        body: 'The program constructed a graph based on data retrieved from IMDB where nodes represent actors/actresses and edges between two nodes represents a movie the corresponding actors/actresses have played a role in together. The program would then let you do various things such as find the minimum number of degrees linking a queried actor to Kevin Bacon (dijkstras, BFS, DFS), create a minimum spanning tree that connects every actor without cycles (Union Find), and predicts future collaborations with a queried actor (Priority Queue Implementation).'
    },
    {
        header: 'MERN (MongoDB, Express.js, React, Node.js) stack Todo List App.',
        body: "My first full stack web application that I did last summer where I taught myself react, how to authorize and authenticate users with auth0, and how to build a RESTful API in express/node.js."
    },
    {
        header: 'Finding and visualizing the correlation between the mood of popular songs and the season.',
        body: "Constructed a script in python that web scraped popular songs off of spotify and used one of spotify's web APIs that extracted features such as danceability, rhythm, tempo , etc. from songs to see if there was a correlation between the types of songs played during different season. Visualized findings through some of python's visual libraries such as matplotlib."
    },
    {
        header: 'Creating an AI that plays 2048.',
        body: 'Implemented a game AI in python that plays 2048 based on expectimax search as well as UI to show the game being played using the pygame library. Added my own heuristic that allowed the AI to achieve a 2048 score almost every time.'
    }
]

const Projects = () => {
    
    const [projectNum, changeProjectNum] = useState(0);

    function handleNext() {
        projectNum >= projectList.length - 1 ?
            changeProjectNum(1) : 
            changeProjectNum(projectNum + 1)
    }

    function handlePrev() {
        changeProjectNum(projectNum - 1);
    }

    return (
        <div>
            <Card.Header className="card-header">{projectList[projectNum]['header']}</Card.Header>
            <Card.Body>
                <Card.Text>
                    {projectList[projectNum]['body']}
                    {projectNum === 4 && (
                         <img style={{ width:"90%" }} src="./TodoAppDiagram.png" alt="diagram" />
                    )}
                </Card.Text>
                {projectNum > 0 && (
                    <Button variant="secondary" style={{ marginRight: '25px' }} onClick={handlePrev}>
                        {projectNum === 1 ? "Back To Intro" : "Previous Project"}
                    </Button>
                )}
                <Button variant="secondary" onClick={handleNext}>
                    {projectNum === 0 || projectNum === projectList.length - 1 ? "Go To First Project" : "Next Project"}
                </Button>
            </Card.Body>
        </div>
    )
}

export default Projects;
